@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
:root {
    --primary-color: #c2a992;
    --primary-dark: #090909;
    --primary-light: #f8f9fa;
    --secondary-color: #a6a6a6;
    --red-color: #dc3545;
}

.text-danger,
.color-danger {
    color: var(--red-color);
}

body {
    font-family: 'Lato', sans-serif;
    color: var(--primary-dark);
}
.btn-feria:hover {
    opacity: .8 !important;
}
.btn-feria,
.highlight {
    background-color: var(--primary-color) !important;
    color: #fff !important;
    font-weight: 600;
}

.btn-secondary-feria {
    background-color: var(--secondary-color) !important;
    color: #fff !important;
    font-weight: 400;
}

.badge.badge-aux {
    background-color: var(--primary-color) !important;
    color: var(--primary-dark) !important;
    font-weight: 400;
    font-size: .8em;
    margin-bottom: .5em;
}

.badge.badge-aux-big {
    background-color: var(--primary-color) !important;
    color: var(--primary-dark) !important;
    font-weight: 400;
    font-size: 1em;
}

.badge.highlighted {
    background-color: var(--red-color) !important;
    color: white !important;
}

.table-cell {
    min-height: 50px;
    position: relative;
    border: 1px solid var(--primary-light);
    vertical-align: top;
    padding: 8px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

.table th,
.table td {
    text-align: center;
    vertical-align: middle;
}

.navbar-fixed {
    background-color: var(--primary-light);
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.badge {
    cursor: pointer;
    margin:0 5px 5px;
}

.espectaculo-info {
    font-size: .8em;
}

.espectaculo-info .espacio {
    color: var(--secondary-color);
    text-transform: uppercase;
    display: block;
}

.espectaculo-info span.titulo {
    font-weight: 700;
    font-style: italic;
    display: inline-block;
}

.espectaculo-info-title {
    font-weight: 700;
    font-style: italic;
    font-size: medium;
    text-align: center;
}

.espectaculo-info-pretitle {
    text-transform: uppercase;
    margin: 0;
    text-align: center;
}

.espectaculo-info span.cia {
    text-transform: uppercase;
    display: inline-block;
}

.notification {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 2000;
    display: none;
    padding: 10px;
    border-radius: 5px;
}

.notification-success {
    background-color: #28a745;
    color: white;
}

.notification-error {
    background-color: var(--red-color);
    color: white;
}

.espacio a {
    color: inherit;
}

@media print {
    .navbar,
    .btn-group,
    .input-group {
        display: none;
    }
    .badge {
        font-weight: bold;
    }
}

.custom-popover {
    --bs-popover-max-width: 200px;
    --bs-popover-border-color: var(--primary-color);
    --bs-popover-header-bg: var(--primary-color);
    --bs-popover-header-color: var(--bs-white);
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: .5rem;
}

.col.logout-btn {
    text-align: right;
    padding: 0;
}

.col.logout-btn a {
    color: var(--primary-dark);
    text-decoration: none;
}

.col.logout-btn a:hover {
    color: inherit;
    text-decoration: underline;
}

.placeholder-chips {
    min-width: 25vw;
}

h5.card-title.card-dia {
    font-weight: 700;
}

p.card-text.card-espacio {
    text-transform: uppercase;
    font-size: .9em;
    margin-bottom: .5em;
}

p.text-mute.card-inout {
    color: var(--secondary-color);
    text-transform: uppercase;
    font-size: .9em;
    margin: 0;
}

img.mb-5.img-center {
    margin: 0 auto;
}

span#user-area {
    display: block;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

html,
body {
    height: 100%;
    background-color: #f5f5f5;
}

.text-align-center {
    text-align: center !important;
}

.text-center {
    display: flex;
    align-items: center;
    padding-top: 25vh;
}

.btn-dark.text-light{
    margin-right:11px !important;
}

.text-align-center{
    text-align: center;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0;
    margin-top: 0;
}

.form-signin input[type="text"] {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
}

.form-signin .input-group-text {
    margin: 0;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.card-body {
    padding-bottom: 0;
}